<div class="Constrain">
  <slot />
</div>


<style lang="scss">
.Constrain {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mobile  { max-width: $section_main_mobile; }
  @include tablet  { max-width: $section_main_tablet; }
  @include desktop { max-width: $section_main_desktop; }
}
</style>
